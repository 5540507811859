import typeW from "./typeWhite.png";
import "./Header.css";
import { Component } from "react";

class Header extends Component {
  render() {
    return (
      <header className="Header">
        <div class="banner">
          <div class="evenesy">
            <a href="https://evenesy.com">
              <img src={typeW} alt="evenesy logo" />
            </a>
          </div>
          <div class="presentation">
            <p>
              EVENESY is always looking for new solutions to the problems we
              face on a daily basis, now we take another step towards the
              future. Welcome to Slick Quik.
            </p>
            <a href="https://evenesy.com">
              <button>Discovery EVENESY</button>
            </a>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
